.modal-content {
    border-radius: @border-radius-small;
}
.modal-header {
    padding-right: 10px;
    .close {
        margin-top: -10px;
    }
}
.modal-title {
    line-height: @line-height-computed;
}

.close {
    cursor: pointer;
    display: inline-block;
    .transition(all .3s ease-in-out);
    height: 40px;
    padding: 5px;
    vertical-align: middle;
    &:hover, &:focus {
        i {
            .rgba-black(.1);
        }
    }
    i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h1;
        border-radius: 50%;
        padding: 0;
        line-height: 30px;
        width: 30px;
        .text-center;
    }
}

.iframe-container {
    padding-bottom: 110%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.iframe-container iframe,
.iframe-container object,
.iframe-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}