.@{md-css-prefix}-apps:before {
  content: "\f292";
}
.@{md-css-prefix}-cancel:before {
  content: "\f293";
}
.@{md-css-prefix}-arrow-drop-down-circle:before {
  content: "\f294";
}
.@{md-css-prefix}-arrow-drop-down:before {
  content: "\f295";
}
.@{md-css-prefix}-arrow-drop-up:before {
  content: "\f296";
}
.@{md-css-prefix}-arrow-back:before {
  content: "\f297";
}
.@{md-css-prefix}-arrow-forward:before {
  content: "\f298";
}
.@{md-css-prefix}-check:before {
  content: "\f299";
}
.@{md-css-prefix}-close:before {
  content: "\f29a";
}
.@{md-css-prefix}-chevron-left:before {
  content: "\f29b";
}
.@{md-css-prefix}-chevron-right:before {
  content: "\f29c";
}
.@{md-css-prefix}-expand-less:before {
  content: "\f29d";
}
.@{md-css-prefix}-expand-more:before {
  content: "\f29e";
}
.@{md-css-prefix}-fullscreen:before {
  content: "\f29f";
}
.@{md-css-prefix}-fullscreen-exit:before {
  content: "\f2a0";
}
.@{md-css-prefix}-menu:before {
  content: "\f2a1";
}
.@{md-css-prefix}-more-horiz:before {
  content: "\f2a2";
}
.@{md-css-prefix}-more-vert:before {
  content: "\f2a3";
}
.@{md-css-prefix}-refresh:before {
  content: "\f2a4";
}
.@{md-css-prefix}-unfold-less:before {
  content: "\f2a5";
}
.@{md-css-prefix}-unfold-more:before {
  content: "\f2a6";
}