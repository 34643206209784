.@{md-css-prefix}-access-alarm:before {
  content: "\f11d";
}
.@{md-css-prefix}-access-alarms:before {
  content: "\f11e";
}
.@{md-css-prefix}-access-time:before {
  content: "\f11f";
}
.@{md-css-prefix}-add-alarm:before {
  content: "\f120";
}
.@{md-css-prefix}-airplanemode-off:before {
  content: "\f121";
}
.@{md-css-prefix}-airplanemode-on:before {
  content: "\f122";
}
.@{md-css-prefix}-battery-20:before {
  content: "\f123";
}
.@{md-css-prefix}-battery-30:before {
  content: "\f124";
}
.@{md-css-prefix}-battery-50:before {
  content: "\f125";
}
.@{md-css-prefix}-battery-60:before {
  content: "\f126";
}
.@{md-css-prefix}-battery-80:before {
  content: "\f127";
}
.@{md-css-prefix}-battery-90:before {
  content: "\f128";
}
.@{md-css-prefix}-battery-alert:before {
  content: "\f129";
}
.@{md-css-prefix}-battery-charging-20:before {
  content: "\f12a";
}
.@{md-css-prefix}-battery-charging-30:before {
  content: "\f12b";
}
.@{md-css-prefix}-battery-charging-50:before {
  content: "\f12c";
}
.@{md-css-prefix}-battery-charging-60:before {
  content: "\f12d";
}
.@{md-css-prefix}-battery-charging-80:before {
  content: "\f12e";
}
.@{md-css-prefix}-battery-charging-90:before {
  content: "\f12f";
}
.@{md-css-prefix}-battery-charging-full:before {
  content: "\f130";
}
.@{md-css-prefix}-battery-full:before {
  content: "\f131";
}
.@{md-css-prefix}-battery-std:before {
  content: "\f132";
}
.@{md-css-prefix}-battery-unknown:before {
  content: "\f133";
}
.@{md-css-prefix}-bluetooth:before {
  content: "\f134";
}
.@{md-css-prefix}-bluetooth-connected:before {
  content: "\f135";
}
.@{md-css-prefix}-bluetooth-disabled:before {
  content: "\f136";
}
.@{md-css-prefix}-bluetooth-searching:before {
  content: "\f137";
}
.@{md-css-prefix}-brightness-auto:before {
  content: "\f138";
}
.@{md-css-prefix}-brightness-high:before {
  content: "\f139";
}
.@{md-css-prefix}-brightness-low:before {
  content: "\f13a";
}
.@{md-css-prefix}-brightness-medium:before {
  content: "\f13b";
}
.@{md-css-prefix}-data-usage:before {
  content: "\f13c";
}
.@{md-css-prefix}-developer-mode:before {
  content: "\f13d";
}
.@{md-css-prefix}-devices:before {
  content: "\f13e";
}
.@{md-css-prefix}-dvr:before {
  content: "\f13f";
}
.@{md-css-prefix}-gps-fixed:before {
  content: "\f140";
}
.@{md-css-prefix}-gps-not-fixed:before {
  content: "\f141";
}
.@{md-css-prefix}-gps-off:before {
  content: "\f142";
}
.@{md-css-prefix}-location-disabled:before {
  content: "\f143";
}
.@{md-css-prefix}-location-searching:before {
  content: "\f144";
}
.@{md-css-prefix}-multitrack-audio:before {
  content: "\f145";
}
.@{md-css-prefix}-network-cell:before {
  content: "\f146";
}
.@{md-css-prefix}-network-wifi:before {
  content: "\f147";
}
.@{md-css-prefix}-nfc:before {
  content: "\f148";
}
.@{md-css-prefix}-now-wallpaper:before {
  content: "\f149";
}
.@{md-css-prefix}-now-widgets:before {
  content: "\f14a";
}
.@{md-css-prefix}-screen-lock-landscape:before {
  content: "\f14b";
}
.@{md-css-prefix}-screen-lock-portrait:before {
  content: "\f14c";
}
.@{md-css-prefix}-screen-lock-rotation:before {
  content: "\f14d";
}
.@{md-css-prefix}-screen-rotation:before {
  content: "\f14e";
}
.@{md-css-prefix}-sd-storage:before {
  content: "\f14f";
}
.@{md-css-prefix}-settings-system-daydream:before {
  content: "\f150";
}
.@{md-css-prefix}-signal-cellular-0-bar:before {
  content: "\f151";
}
.@{md-css-prefix}-signal-cellular-1-bar:before {
  content: "\f152";
}
.@{md-css-prefix}-signal-cellular-2-bar:before {
  content: "\f153";
}
.@{md-css-prefix}-signal-cellular-3-bar:before {
  content: "\f154";
}
.@{md-css-prefix}-signal-cellular-4-bar:before {
  content: "\f155";
}
.@{md-css-prefix}-signal-cellular-connected-no-internet-0-bar:before {
  content: "\f156";
}
.@{md-css-prefix}-signal-cellular-connected-no-internet-1-bar:before {
  content: "\f157";
}
.@{md-css-prefix}-signal-cellular-connected-no-internet-2-bar:before {
  content: "\f158";
}
.@{md-css-prefix}-signal-cellular-connected-no-internet-3-bar:before {
  content: "\f159";
}
.@{md-css-prefix}-signal-cellular-connected-no-internet-4-bar:before {
  content: "\f15a";
}
.@{md-css-prefix}-signal-cellular-no-sim:before {
  content: "\f15b";
}
.@{md-css-prefix}-signal-cellular-null:before {
  content: "\f15c";
}
.@{md-css-prefix}-signal-cellular-off:before {
  content: "\f15d";
}
.@{md-css-prefix}-signal-wifi-0-bar:before {
  content: "\f15e";
}
.@{md-css-prefix}-signal-wifi-1-bar:before {
  content: "\f15f";
}
.@{md-css-prefix}-signal-wifi-2-bar:before {
  content: "\f160";
}
.@{md-css-prefix}-signal-wifi-3-bar:before {
  content: "\f161";
}
.@{md-css-prefix}-signal-wifi-4-bar:before {
  content: "\f162";
}
.@{md-css-prefix}-signal-wifi-off:before {
  content: "\f163";
}
.@{md-css-prefix}-storage:before {
  content: "\f164";
}
.@{md-css-prefix}-usb:before {
  content: "\f165";
}
.@{md-css-prefix}-wifi-lock:before {
  content: "\f166";
}
.@{md-css-prefix}-wifi-tethering:before {
  content: "\f167";
}