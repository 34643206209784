.@{md-css-prefix}-album:before {
  content: "\f0a2";
}
.@{md-css-prefix}-av-timer:before {
  content: "\f0a3";
}
.@{md-css-prefix}-closed-caption:before {
  content: "\f0a4";
}
.@{md-css-prefix}-equalizer:before {
  content: "\f0a5";
}
.@{md-css-prefix}-explicit:before {
  content: "\f0a6";
}
.@{md-css-prefix}-fast-forward:before {
  content: "\f0a7";
}
.@{md-css-prefix}-fast-rewind:before {
  content: "\f0a8";
}
.@{md-css-prefix}-games:before {
  content: "\f0a9";
}
.@{md-css-prefix}-hearing:before {
  content: "\f0aa";
}
.@{md-css-prefix}-high-quality:before {
  content: "\f0ab";
}
.@{md-css-prefix}-loop:before {
  content: "\f0ac";
}
.@{md-css-prefix}-mic:before {
  content: "\f0ad";
}
.@{md-css-prefix}-mic-none:before {
  content: "\f0ae";
}
.@{md-css-prefix}-mic-off:before {
  content: "\f0af";
}
.@{md-css-prefix}-movie:before {
  content: "\f0b0";
}
.@{md-css-prefix}-my-library-add:before {
  content: "\f0b1";
}
.@{md-css-prefix}-my-library-books:before {
  content: "\f0b2";
}
.@{md-css-prefix}-my-library-music:before {
  content: "\f0b3";
}
.@{md-css-prefix}-new-releases:before {
  content: "\f0b4";
}
.@{md-css-prefix}-not-interested:before {
  content: "\f0b5";
}
.@{md-css-prefix}-pause:before {
  content: "\f0b6";
}
.@{md-css-prefix}-pause-circle-fill:before {
  content: "\f0b7";
}
.@{md-css-prefix}-pause-circle-outline:before {
  content: "\f0b8";
}
.@{md-css-prefix}-play-arrow:before {
  content: "\f0b9";
}
.@{md-css-prefix}-play-circle-fill:before {
  content: "\f0ba";
}
.@{md-css-prefix}-play-circle-outline:before {
  content: "\f0bb";
}
.@{md-css-prefix}-playlist-add:before {
  content: "\f0bc";
}
.@{md-css-prefix}-play-shopping-bag:before {
  content: "\f0bd";
}
.@{md-css-prefix}-queue:before {
  content: "\f0be";
}
.@{md-css-prefix}-queue-music:before {
  content: "\f0bf";
}
.@{md-css-prefix}-radio:before {
  content: "\f0c0";
}
.@{md-css-prefix}-recent-actors:before {
  content: "\f0c1";
}
.@{md-css-prefix}-repeat:before {
  content: "\f0c2";
}
.@{md-css-prefix}-repeat-one:before {
  content: "\f0c3";
}
.@{md-css-prefix}-replay:before {
  content: "\f0c4";
}
.@{md-css-prefix}-shuffle:before {
  content: "\f0c5";
}
.@{md-css-prefix}-skip-next:before {
  content: "\f0c6";
}
.@{md-css-prefix}-skip-previous:before {
  content: "\f0c7";
}
.@{md-css-prefix}-snooze:before {
  content: "\f0c8";
}
.@{md-css-prefix}-stop:before {
  content: "\f0c9";
}
.@{md-css-prefix}-subtitles:before {
  content: "\f0ca";
}
.@{md-css-prefix}-surround-sound:before {
  content: "\f0cb";
}
.@{md-css-prefix}-videocam:before {
  content: "\f0cc";
}
.@{md-css-prefix}-videocam-off:before {
  content: "\f0cd";
}
.@{md-css-prefix}-video-collection:before {
  content: "\f0ce";
}
.@{md-css-prefix}-volume-down:before {
  content: "\f0cf";
}
.@{md-css-prefix}-volume-mute:before {
  content: "\f0d0";
}
.@{md-css-prefix}-volume-off:before {
  content: "\f0d1";
}
.@{md-css-prefix}-volume-up:before {
  content: "\f0d2";
}
.@{md-css-prefix}-web:before {
  content: "\f0d3";
}