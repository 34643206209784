.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}


@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.alert {
    background: @gray-darker;
    color: #fff;
    border: 0;
    &.bottomleft {
      .animated;
      .fadeInUp;
    }
    &.fixed {
      @media (max-width: @screen-xs-max) {
        bottom: 0;
        left: 0;
        border-radius: 0;
        width: 100%;
        z-index: 10001;
      }
    }
  .close {
      .transition(all .3s ease-in-out);
      text-shadow: none;
      font-weight: 400;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      font-size: 20px;
      display: block;
      line-height: 30px;
      text-align: center;
      height: 30px;
      width: 30px;
      position: absolute;
      color: #fff;
      .opacity(.75);
      &:hover, &:focus {
        color: #fff;
        .opacity(1);
        .rgba-black(.1);
      }
  }

}
.alert-dismissable, .alert-dismissible {
  padding-right: 50px;
}

.alert-danger, .alert-success {
  &:before {
    .icon;
    content: "\f299";
    font-size: 20px;
    vertical-align: middle;
    width: 30px;
  }
}
.alert-success {
  &:before {
    content: "\f299";
    color: @brand-success;
  }
}
.alert-danger {
  &:before {
    content: "\f0a0";
    color: @brand-danger;
  }
}

