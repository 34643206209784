.@{md-css-prefix}-adb:before {
  content: "\f2a7";
}
.@{md-css-prefix}-bluetooth-audio:before {
  content: "\f2a8";
}
.@{md-css-prefix}-disc-full:before {
  content: "\f2a9";
}
.@{md-css-prefix}-dnd-forwardslash:before {
  content: "\f2aa";
}
.@{md-css-prefix}-do-not-disturb:before {
  content: "\f2ab";
}
.@{md-css-prefix}-drive-eta:before {
  content: "\f2ac";
}
.@{md-css-prefix}-event-available:before {
  content: "\f2ad";
}
.@{md-css-prefix}-event-busy:before {
  content: "\f2ae";
}
.@{md-css-prefix}-event-note:before {
  content: "\f2af";
}
.@{md-css-prefix}-folder-special:before {
  content: "\f2b0";
}
.@{md-css-prefix}-mms:before {
  content: "\f2b1";
}
.@{md-css-prefix}-more:before {
  content: "\f2b2";
}
.@{md-css-prefix}-network-locked:before {
  content: "\f2b3";
}
.@{md-css-prefix}-phone-bluetooth-speaker:before {
  content: "\f2b4";
}
.@{md-css-prefix}-phone-forwarded:before {
  content: "\f2b5";
}
.@{md-css-prefix}-phone-in-talk:before {
  content: "\f2b6";
}
.@{md-css-prefix}-phone-locked:before {
  content: "\f2b7";
}
.@{md-css-prefix}-phone-missed:before {
  content: "\f2b8";
}
.@{md-css-prefix}-phone-paused:before {
  content: "\f2b9";
}
.@{md-css-prefix}-play-download:before {
  content: "\f2ba";
}
.@{md-css-prefix}-play-install:before {
  content: "\f2bb";
}
.@{md-css-prefix}-sd-card:before {
  content: "\f2bc";
}
.@{md-css-prefix}-sim-card-alert:before {
  content: "\f2bd";
}
.@{md-css-prefix}-sms:before {
  content: "\f2be";
}
.@{md-css-prefix}-sms-failed:before {
  content: "\f2bf";
}
.@{md-css-prefix}-sync:before {
  content: "\f2c0";
}
.@{md-css-prefix}-sync-disabled:before {
  content: "\f2c1";
}
.@{md-css-prefix}-sync-problem:before {
  content: "\f2c2";
}
.@{md-css-prefix}-system-update:before {
  content: "\f2c3";
}
.@{md-css-prefix}-tap-and-play:before {
  content: "\f2c4";
}
.@{md-css-prefix}-time-to-leave:before {
  content: "\f2c5";
}
.@{md-css-prefix}-vibration:before {
  content: "\f2c6";
}
.@{md-css-prefix}-voice-chat:before {
  content: "\f2c7";
}
.@{md-css-prefix}-vpn-lock:before {
  content: "\f2c8";
}