.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: @brand-primary;
}
.select2-container--default .select2-selection--single {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    border-color: @input-border;
    border-radius: @border-radius-small;
    height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 5px;
    top: 5px;
}
.select2-container--default .select2-results > .select2-results__options {
    max-height: 300px;
}