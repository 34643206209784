.btn {
    // border: 0;
    font-weight: 500;
    border-radius: @border-radius-small;
    padding-left: @padding-large-horizontal;
    padding-right: @padding-large-horizontal;
    // padding: @padding-large-vertical @padding-large-horizontal;
    .transition(all .3s ease-in-out);
}

.btn-default {
    background: transparent;
    color: @gray-dark;
    box-shadow: 0 1px 0 rgba(0,0,0,.05);
    border: 1px solid rgba(0,0,0,.15);
    text-transform: uppercase;
    &:hover {
        background: transparent;
        color: @gray-dark;
        box-shadow: 0 1px 0 rgba(0,0,0,.1);
        border-color: #b8b8b8;
    }
}
.btn-submit {
    margin-right: 12px;
}
.btn-primary {
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
    text-transform: uppercase;
    &:hover {
        box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    }
    &:focus {
        box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
    }
}
.btn-round {
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    border-radius: 50%;
    height: 56px;
    width: 56px;
    .text-center;
    padding: 0;
    &:hover {
        box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
    }
    .md {
        font-size: 28px;
        position: relative;
        top: 8px;
        .transition(all .3s ease-in-out);
    }
    &:hover {
        .md {
            .rotate(-360deg);
        }
    }
}
.btn-icon {
    background: transparent;
    color: darken(@gray-light, 20%);
    display: inline-block;
    border: 0;
    cursor: pointer;
    height: 40px;
    padding: 5px 0;
    &:hover, &:focus {
        color: @gray-darker;
    }
    &:focus {
        i {
            .rgba-black(.1);
        }
    }
    i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h1;
        border-radius: 50%;
        .text-center;
        padding: 0;
        line-height: 30px;
        width: 30px;
    }
}
.btn-add {
    background: @brand-accent;
    color: #fff;
    &:hover, &:focus {
        color: #fff;
    }
}
.btn-group--events { width: 100%; }
.btn-group--events .btn--event {
    .text-center;
    white-space: normal;
    min-height: 120px;
    margin-bottom: @grid-gutter-width / 2;
}
.btn-group--events .btn--event .icon-avatar {
    margin: 8px auto;
}
.btn-group--events .btn--event.active .icon-avatar {
    background: @brand-primary;
}
[data-toggle="buttons"] {
    .btn,
    > .btn-group > .btn {
        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            clip: rect(0,0,0,0);
            pointer-events: none;
        }
    }
}
