.@{md-css-prefix}-3d-rotation:before {
  content: "\f000";
}
.@{md-css-prefix}-accessibility:before {
  content: "\f001";
}
.@{md-css-prefix}-account-balance:before {
  content: "\f002";
}
.@{md-css-prefix}-account-balance-wallet:before {
  content: "\f003";
}
.@{md-css-prefix}-account-box:before {
  content: "\f004";
}
.@{md-css-prefix}-account-child:before {
  content: "\f005";
}
.@{md-css-prefix}-account-circle:before {
  content: "\f006";
}
.@{md-css-prefix}-add-shopping-cart:before {
  content: "\f007";
}
.@{md-css-prefix}-alarm:before {
  content: "\f008";
}
.@{md-css-prefix}-alarm-add:before {
  content: "\f009";
}
.@{md-css-prefix}-alarm-off:before {
  content: "\f00a";
}
.@{md-css-prefix}-alarm-on:before {
  content: "\f00b";
}
.@{md-css-prefix}-android:before {
  content: "\f00c";
}
.@{md-css-prefix}-announcement:before {
  content: "\f00d";
}
.@{md-css-prefix}-aspect-ratio:before {
  content: "\f00e";
}
.@{md-css-prefix}-assessment:before {
  content: "\f00f";
}
.@{md-css-prefix}-assignment:before {
  content: "\f010";
}
.@{md-css-prefix}-assignment-ind:before {
  content: "\f011";
}
.@{md-css-prefix}-assignment-late:before {
  content: "\f012";
}
.@{md-css-prefix}-assignment-return:before {
  content: "\f013";
}
.@{md-css-prefix}-assignment-returned:before {
  content: "\f014";
}
.@{md-css-prefix}-assignment-turned-in:before {
  content: "\f015";
}
.@{md-css-prefix}-autorenew:before {
  content: "\f016";
}
.@{md-css-prefix}-backup:before {
  content: "\f017";
}
.@{md-css-prefix}-book:before {
  content: "\f018";
}
.@{md-css-prefix}-bookmark:before {
  content: "\f019";
}
.@{md-css-prefix}-bookmark-outline:before {
  content: "\f01a";
}
.@{md-css-prefix}-bug-report:before {
  content: "\f01b";
}
.@{md-css-prefix}-cached:before {
  content: "\f01c";
}
.@{md-css-prefix}-class:before {
  content: "\f01d";
}
.@{md-css-prefix}-credit-card:before {
  content: "\f01e";
}
.@{md-css-prefix}-dashboard:before {
  content: "\f01f";
}
.@{md-css-prefix}-delete:before {
  content: "\f020";
}
.@{md-css-prefix}-description:before {
  content: "\f021";
}
.@{md-css-prefix}-dns:before {
  content: "\f022";
}
.@{md-css-prefix}-done:before {
  content: "\f023";
}
.@{md-css-prefix}-done-all:before {
  content: "\f024";
}
.@{md-css-prefix}-event:before {
  content: "\f025";
}
.@{md-css-prefix}-exit-to-app:before {
  content: "\f026";
}
.@{md-css-prefix}-explore:before {
  content: "\f027";
}
.@{md-css-prefix}-extension:before {
  content: "\f028";
}
.@{md-css-prefix}-face-unlock:before {
  content: "\f029";
}
.@{md-css-prefix}-favorite:before {
  content: "\f02a";
}
.@{md-css-prefix}-favorite-outline:before {
  content: "\f02b";
}
.@{md-css-prefix}-find-in-page:before {
  content: "\f02c";
}
.@{md-css-prefix}-find-replace:before {
  content: "\f02d";
}
.@{md-css-prefix}-flip-to-back:before {
  content: "\f02e";
}
.@{md-css-prefix}-flip-to-front:before {
  content: "\f02f";
}
.@{md-css-prefix}-get-app:before {
  content: "\f030";
}
.@{md-css-prefix}-grade:before {
  content: "\f031";
}
.@{md-css-prefix}-group-work:before {
  content: "\f032";
}
.@{md-css-prefix}-help:before {
  content: "\f033";
}
.@{md-css-prefix}-highlight-remove:before {
  content: "\f034";
}
.@{md-css-prefix}-history:before {
  content: "\f035";
}
.@{md-css-prefix}-home:before {
  content: "\f036";
}
.@{md-css-prefix}-https:before {
  content: "\f037";
}
.@{md-css-prefix}-info:before {
  content: "\f038";
}
.@{md-css-prefix}-info-outline:before {
  content: "\f039";
}
.@{md-css-prefix}-input:before {
  content: "\f03a";
}
.@{md-css-prefix}-invert-colors:before {
  content: "\f03b";
}
.@{md-css-prefix}-label:before {
  content: "\f03c";
}
.@{md-css-prefix}-label-outline:before {
  content: "\f03d";
}
.@{md-css-prefix}-language:before {
  content: "\f03e";
}
.@{md-css-prefix}-launch:before {
  content: "\f03f";
}
.@{md-css-prefix}-list:before {
  content: "\f040";
}
.@{md-css-prefix}-lock:before {
  content: "\f041";
}
.@{md-css-prefix}-lock-open:before {
  content: "\f042";
}
.@{md-css-prefix}-lock-outline:before {
  content: "\f043";
}
.@{md-css-prefix}-loyalty:before {
  content: "\f044";
}
.@{md-css-prefix}-markunread-mailbox:before {
  content: "\f045";
}
.@{md-css-prefix}-note-add:before {
  content: "\f046";
}
.@{md-css-prefix}-open-in-browser:before {
  content: "\f047";
}
.@{md-css-prefix}-open-in-new:before {
  content: "\f048";
}
.@{md-css-prefix}-open-with:before {
  content: "\f049";
}
.@{md-css-prefix}-pageview:before {
  content: "\f04a";
}
.@{md-css-prefix}-payment:before {
  content: "\f04b";
}
.@{md-css-prefix}-perm-camera-mic:before {
  content: "\f04c";
}
.@{md-css-prefix}-perm-contact-cal:before {
  content: "\f04d";
}
.@{md-css-prefix}-perm-data-setting:before {
  content: "\f04e";
}
.@{md-css-prefix}-perm-device-info:before {
  content: "\f04f";
}
.@{md-css-prefix}-perm-identity:before {
  content: "\f050";
}
.@{md-css-prefix}-perm-media:before {
  content: "\f051";
}
.@{md-css-prefix}-perm-phone-msg:before {
  content: "\f052";
}
.@{md-css-prefix}-perm-scan-wifi:before {
  content: "\f053";
}
.@{md-css-prefix}-picture-in-picture:before {
  content: "\f054";
}
.@{md-css-prefix}-polymer:before {
  content: "\f055";
}
.@{md-css-prefix}-print:before {
  content: "\f056";
}
.@{md-css-prefix}-query-builder:before {
  content: "\f057";
}
.@{md-css-prefix}-question-answer:before {
  content: "\f058";
}
.@{md-css-prefix}-receipt:before {
  content: "\f059";
}
.@{md-css-prefix}-redeem:before {
  content: "\f05a";
}
.@{md-css-prefix}-report-problem:before {
  content: "\f05b";
}
.@{md-css-prefix}-restore:before {
  content: "\f05c";
}
.@{md-css-prefix}-room:before {
  content: "\f05d";
}
.@{md-css-prefix}-schedule:before {
  content: "\f05e";
}
.@{md-css-prefix}-search:before {
  content: "\f05f";
}
.@{md-css-prefix}-settings:before {
  content: "\f060";
}
.@{md-css-prefix}-settings-applications:before {
  content: "\f061";
}
.@{md-css-prefix}-settings-backup-restore:before {
  content: "\f062";
}
.@{md-css-prefix}-settings-bluetooth:before {
  content: "\f063";
}
.@{md-css-prefix}-settings-cell:before {
  content: "\f064";
}
.@{md-css-prefix}-settings-display:before {
  content: "\f065";
}
.@{md-css-prefix}-settings-ethernet:before {
  content: "\f066";
}
.@{md-css-prefix}-settings-input-antenna:before {
  content: "\f067";
}
.@{md-css-prefix}-settings-input-component:before {
  content: "\f068";
}
.@{md-css-prefix}-settings-input-composite:before {
  content: "\f069";
}
.@{md-css-prefix}-settings-input-hdmi:before {
  content: "\f06a";
}
.@{md-css-prefix}-settings-input-svideo:before {
  content: "\f06b";
}
.@{md-css-prefix}-settings-overscan:before {
  content: "\f06c";
}
.@{md-css-prefix}-settings-phone:before {
  content: "\f06d";
}
.@{md-css-prefix}-settings-power:before {
  content: "\f06e";
}
.@{md-css-prefix}-settings-remote:before {
  content: "\f06f";
}
.@{md-css-prefix}-settings-voice:before {
  content: "\f070";
}
.@{md-css-prefix}-shop:before {
  content: "\f071";
}
.@{md-css-prefix}-shopping-basket:before {
  content: "\f072";
}
.@{md-css-prefix}-shopping-cart:before {
  content: "\f073";
}
.@{md-css-prefix}-shop-two:before {
  content: "\f074";
}
.@{md-css-prefix}-speaker-notes:before {
  content: "\f075";
}
.@{md-css-prefix}-spellcheck:before {
  content: "\f076";
}
.@{md-css-prefix}-star-rate:before {
  content: "\f077";
}
.@{md-css-prefix}-stars:before {
  content: "\f078";
}
.@{md-css-prefix}-store:before {
  content: "\f079";
}
.@{md-css-prefix}-subject:before {
  content: "\f07a";
}
.@{md-css-prefix}-swap-horiz:before {
  content: "\f07b";
}
.@{md-css-prefix}-swap-vert:before {
  content: "\f07c";
}
.@{md-css-prefix}-swap-vert-circle:before {
  content: "\f07d";
}
.@{md-css-prefix}-system-update-tv:before {
  content: "\f07e";
}
.@{md-css-prefix}-tab:before {
  content: "\f07f";
}
.@{md-css-prefix}-tab-unselected:before {
  content: "\f080";
}
.@{md-css-prefix}-theaters:before {
  content: "\f081";
}
.@{md-css-prefix}-thumb-down:before {
  content: "\f082";
}
.@{md-css-prefix}-thumbs-up-down:before {
  content: "\f083";
}
.@{md-css-prefix}-thumb-up:before {
  content: "\f084";
}
.@{md-css-prefix}-toc:before {
  content: "\f085";
}
.@{md-css-prefix}-today:before {
  content: "\f086";
}
.@{md-css-prefix}-track-changes:before {
  content: "\f087";
}
.@{md-css-prefix}-translate:before {
  content: "\f088";
}
.@{md-css-prefix}-trending-down:before {
  content: "\f089";
}
.@{md-css-prefix}-trending-neutral:before {
  content: "\f08a";
}
.@{md-css-prefix}-trending-up:before {
  content: "\f08b";
}
.@{md-css-prefix}-turned-in:before {
  content: "\f08c";
}
.@{md-css-prefix}-turned-in-not:before {
  content: "\f08d";
}
.@{md-css-prefix}-verified-user:before {
  content: "\f08e";
}
.@{md-css-prefix}-view-agenda:before {
  content: "\f08f";
}
.@{md-css-prefix}-view-array:before {
  content: "\f090";
}
.@{md-css-prefix}-view-carousel:before {
  content: "\f091";
}
.@{md-css-prefix}-view-column:before {
  content: "\f092";
}
.@{md-css-prefix}-view-day:before {
  content: "\f093";
}
.@{md-css-prefix}-view-headline:before {
  content: "\f094";
}
.@{md-css-prefix}-view-list:before {
  content: "\f095";
}
.@{md-css-prefix}-view-module:before {
  content: "\f096";
}
.@{md-css-prefix}-view-quilt:before {
  content: "\f097";
}
.@{md-css-prefix}-view-stream:before {
  content: "\f098";
}
.@{md-css-prefix}-view-week:before {
  content: "\f099";
}
.@{md-css-prefix}-visibility:before {
  content: "\f09a";
}
.@{md-css-prefix}-visibility-off:before {
  content: "\f09b";
}
.@{md-css-prefix}-wallet-giftcard:before {
  content: "\f09c";
}
.@{md-css-prefix}-wallet-membership:before {
  content: "\f09d";
}
.@{md-css-prefix}-wallet-travel:before {
  content: "\f09e";
}
.@{md-css-prefix}-work:before {
  content: "\f09f";
}