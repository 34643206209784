*, :active, :hover {
    outline: 0 !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}
*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}
a:hover, a:focus {
    text-decoration: none;
}

html, body {
    height: 100%;
}
th, strong, label, dt {
    font-weight: 500;
}
body {
    background: @gray-lighter;
    // padding-top: @navbar-height;
}
.secondary {
    font-size: @font-size-small;
    color: lighten(@gray, 20%);
    .dark {
        color: @gray-dark;
    }
}

.fixed {
    position: fixed;
    margin: 0;
    z-index: 980;
}
.bottomleft, .bottomright {
    bottom: 20px;
}
.bottomleft {
    left: 20px;
    @media (min-width: @screen-lg) {
        left: @navbar-width + 20;
    }
}
.bottomright {
    right: 20px;
}
.icon {
  display: inline-block;
  font: normal normal normal 14px/1 'Material Design Iconic Font';
  font-size: inherit;
}
.dropdown {
    &.open {
        .btn-icon {
            i {
                color: @gray-darker;
                .rgba-black(.1);
            }
        }
        .tooltip {
            .opacity(0);
        }
    }
}

.dropdown-menu {
    display: block;
    border-radius: 0;
    .transition(all .15s ease-in-out);
    .scale(0);
    .opacity(0);
    transform-origin: 100% 0;
    .open & {
        .scale(1);
        .opacity(1);
    }
}