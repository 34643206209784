form {
    margin: 0 auto;
    .row {
        >div {
            margin-bottom: @grid-gutter-width / 2;
        }
        label {
            display: block;
            padding: 4px 0;
        }

    }
}
.control-label {
    font-weight: 400;
}
.form-group {
    @media (min-width: @screen-sm-min) {
        margin-bottom: @grid-gutter-width;
    }
    &.form-actions {
        margin-bottom: 0;
    }
}
.form-control {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
    &:focus {
        box-shadow: inset 0 1px 1px rgba(0,0,0,.1) !important;
    }
}

fieldset {
    padding: 0 (@grid-gutter-width /2) 0;
    border-bottom: 1px solid @gray-lighter;
    margin: 0  (-@grid-gutter-width / 2) @grid-gutter-width / 2;
    position: relative;
    @media (min-width: @screen-sm-min) {
        padding: 0 @grid-gutter-width (@grid-gutter-width /2);
        margin: 0  (-@grid-gutter-width) @grid-gutter-width;
    }

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
    }
}

.bootbox-body fieldset {
    @media (min-width: @screen-sm-min) {
        padding: 0 @grid-gutter-width (@grid-gutter-width /2);
        margin: 0  (-@grid-gutter-width / 2) ( @grid-gutter-width / 2 );
    }
}
.bootbox-body form .row > div {
    margin-bottom: (@grid-gutter-width / 4);
}
.bootbox-body label {
    margin-bottom: 0;
}

legend {
    color: @gray-darker;
    border: 0;
    font-size: @font-size-large;
    font-weight: 500;
    line-height: @line-height-computed;
    padding: 0;
    margin: 0 0 (@grid-gutter-width /2);
    @media (min-width: @screen-sm-min) {
    }
}

.control-label--required:after {
    content: ' *';
    color: @brand-danger;
}

.help-block {
    color: darken(@gray-light, 20%);
    margin-bottom: 0;
    font-size: @font-size-small;
}
.form-control--xs {
    .make-sm-column(2);
}
.form-control--sm {
    .make-sm-column(4);
}
.form-control--md {
    .make-sm-column(6);
}
.form-control--lg {
    .make-sm-column(12);
}
.form-group--text .form-control {
    .make-sm-column(1);
    float: none !important;
    display: inline;
    margin: 0 4px 0 8px;
}
.form-control--md .btn {
     margin-top: 28px;
 }