.avatar {
    .img-circle;
}
.icon-avatar {
    background: @gray-light;
    color: #fff;
    border-radius: 50%;
    line-height: 40px;
    height: 40px;
    width: 40px;
    position: relative;
    .text-center;
    .md {
        font-size: 30px;
    }
}
.icon-avatar .icon-avatar--pointer {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 14px;
    right: 14px;
    font-size: 20px;
}
