// Icon sizes
// -------------------------
.@{md-css-prefix}-lg {
  font-size: 1.5em;
  line-height: .5em;
  vertical-align: -35%;
}
.@{md-css-prefix}-2x { font-size: 2em; }
.@{md-css-prefix}-3x { font-size: 3em; }
.@{md-css-prefix}-4x { font-size: 4em; }
.@{md-css-prefix}-5x { font-size: 5em; }