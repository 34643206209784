// Core Class Definition
// -------------------------
[class^="@{md-css-prefix}-"], [class*=" @{md-css-prefix}-"] {
  display: inline-block;
  font: normal normal normal 14px/1 'Material Design Iconic Font';
  font-size: inherit;
  speak: none;
  text-rendering: auto;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.@{md-css-prefix} {
  line-height: inherit;
  vertical-align: bottom;
}