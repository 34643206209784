// Bordered icons
// -------------------------
.@{md-css-prefix}-border {
  padding: .2em .25em .15em;
  border: solid .08em @md-border-color;
  border-radius: .1em;
}

.@{md-css-prefix}-border-circle {
  padding: .2em .25em .15em;
  border: solid .08em @md-border-color;
  border-radius: 50%;
}