//
// Checkboxes
// --------------------------------------------------

@font-family-icon: 'Material Design Iconic Font';

.checkbox-variant(@parent, @color) {
    .@{parent} label:hover input[type="checkbox"]:not(:disabled) + span {
        &::before {
            border-color: @color;
        }
    }
    .@{parent} input[type="checkbox"]:checked + span {
        &::before {
            background-color: @color;
            border-color: @color;
        }
        &::after {
            color: #fff;
        }
    }
}


.checkbox{
    margin: 0;
    padding-left: 25px;
    label {
        padding: 0;
    }
    table & { margin: 0; }
    span{
        display: inline-block;
        position: relative;
        vertical-align: top;

        &::before{
            content: "";
            display: inline-block;
            position: absolute;
            width: 18px;
            height: 18px;
            left: 0;
            margin-top: 1px;
            margin-left: -25px;
            border: 1px solid @input-border;
            border-radius: @border-radius-base;
            background-color: #fff;
            .transition(~"border 0.15s ease-in-out, color 0.15s ease-in-out");
        }

        &::after{
            display: inline-block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            margin-left: -25px;
            padding-left: 3px;
            padding-top: 2px;
            font-size: @font-size-small;
        }
    }

    input[type="checkbox"]{
        opacity: 0;
        z-index: 1;

        &:focus + span::before{
            // .tab-focus();
        }

        &:checked + span::after{
            font-family: @font-family-icon;
            content: "\f299";
        }

        &:disabled + span{
            opacity: 0.65;

            &::before{
                background-color: @input-bg-disabled;
                cursor: not-allowed;
            }
        }

    }

    &.checkbox-circle span::before{
        border-radius: 50%;
    }

    &.checkbox-single span{
        padding: 0;
        height: 18px;
    }

    &.checkbox-inline{
        margin-top: 0;
    }
}

.checkbox-variant(checkbox, @brand-primary);
.checkbox-variant(has-error, @brand-danger);
.checkbox-variant(has-warning, @brand-warning);
.checkbox-variant(has-success, @brand-success);

//
// Radios
// --------------------------------------------------

.radio-variant(@parent, @color) {
    .@{parent} label:hover input[type="radio"]:not(:disabled) + span {
        &::before {
            border-color: @color;
        }
    }
    .@{parent} input[type="radio"]{
        & + span{
            &::after{
                background-color: @color;
            }
        }
        &:checked + span{
            &::before {
                border-color: @color;
            }
            &::after{
                background-color: @color;
            }
        }
    }
}

.radio{
    padding-left: 25px;

    label {
        padding: 0;
    }
    span{
        display: inline-block;
        position: relative;

        &::before{
            content: "";
            display: inline-block;
            position: absolute;
            width: 18px;
            height: 18px;
            left: 0;
            margin-top: 1px;
            margin-left: -25px;
            border: 1px solid @input-border;
            border-radius: 50%;
            background-color: #fff;
            .transition(border 0.15s ease-in-out);
        }

        &::after{
            display: inline-block;
            position: absolute;
            content: " ";
            width: 6px;
            height: 6px;
            left: 6px;
            top: 7px;
            margin-left: -25px;
            border-radius: 50%;
            background-color: @brand-primary;
            .scale(0, 0);

            .transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
            //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
        }
    }

    input[type="radio"]{
        opacity: 0;
        z-index: 1;

        &:focus + span::before{
            // .tab-focus();
        }

        &:checked + span::after{
            .scale(1, 1);
        }

        &:disabled + span{
            opacity: 0.65;

            &::before{
                cursor: not-allowed;
            }
        }

    }

    &.radio-inline{
        margin-top: 0;
    }

    &.radio-single span{
        height: 18px;
    }
}

.radio-variant(radio, @brand-primary);
.radio-variant(has-error, @brand-danger);
.radio-variant(has-warning, @brand-warning);
.radio-variant(has-success, @brand-success);