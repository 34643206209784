.@{md-css-prefix}-add-to-photos:before {
  content: "\f1d2";
}
.@{md-css-prefix}-adjust:before {
  content: "\f1d3";
}
.@{md-css-prefix}-assistant-photo:before {
  content: "\f1d4";
}
.@{md-css-prefix}-audiotrack:before {
  content: "\f1d5";
}
.@{md-css-prefix}-blur-circular:before {
  content: "\f1d6";
}
.@{md-css-prefix}-blur-linear:before {
  content: "\f1d7";
}
.@{md-css-prefix}-blur-off:before {
  content: "\f1d8";
}
.@{md-css-prefix}-blur-on:before {
  content: "\f1d9";
}
.@{md-css-prefix}-brightness-1:before {
  content: "\f1da";
}
.@{md-css-prefix}-brightness-2:before {
  content: "\f1db";
}
.@{md-css-prefix}-brightness-3:before {
  content: "\f1dc";
}
.@{md-css-prefix}-brightness-4:before {
  content: "\f1dd";
}
.@{md-css-prefix}-brightness-5:before {
  content: "\f1de";
}
.@{md-css-prefix}-brightness-6:before {
  content: "\f1df";
}
.@{md-css-prefix}-brightness-7:before {
  content: "\f1e0";
}
.@{md-css-prefix}-brush:before {
  content: "\f1e1";
}
.@{md-css-prefix}-camera:before {
  content: "\f1e2";
}
.@{md-css-prefix}-camera-alt:before {
  content: "\f1e3";
}
.@{md-css-prefix}-camera-front:before {
  content: "\f1e4";
}
.@{md-css-prefix}-camera-rear:before {
  content: "\f1e5";
}
.@{md-css-prefix}-camera-roll:before {
  content: "\f1e6";
}
.@{md-css-prefix}-center-focus-strong:before {
  content: "\f1e7";
}
.@{md-css-prefix}-center-focus-weak:before {
  content: "\f1e8";
}
.@{md-css-prefix}-collections:before {
  content: "\f1e9";
}
.@{md-css-prefix}-colorize:before {
  content: "\f1ea";
}
.@{md-css-prefix}-color-lens:before {
  content: "\f1eb";
}
.@{md-css-prefix}-compare:before {
  content: "\f1ec";
}
.@{md-css-prefix}-control-point:before {
  content: "\f1ed";
}
.@{md-css-prefix}-control-point-duplicate:before {
  content: "\f1ee";
}
.@{md-css-prefix}-crop:before {
  content: "\f1ef";
}
.@{md-css-prefix}-crop-3-2:before {
  content: "\f1f0";
}
.@{md-css-prefix}-crop-5-4:before {
  content: "\f1f1";
}
.@{md-css-prefix}-crop-7-5:before {
  content: "\f1f2";
}
.@{md-css-prefix}-crop-16-9:before {
  content: "\f1f3";
}
.@{md-css-prefix}-crop-din:before {
  content: "\f1f4";
}
.@{md-css-prefix}-crop-free:before {
  content: "\f1f5";
}
.@{md-css-prefix}-crop-landscape:before {
  content: "\f1f6";
}
.@{md-css-prefix}-crop-original:before {
  content: "\f1f7";
}
.@{md-css-prefix}-crop-portrait:before {
  content: "\f1f8";
}
.@{md-css-prefix}-crop-square:before {
  content: "\f1f9";
}
.@{md-css-prefix}-dehaze:before {
  content: "\f1fa";
}
.@{md-css-prefix}-details:before {
  content: "\f1fb";
}
.@{md-css-prefix}-edit:before {
  content: "\f1fc";
}
.@{md-css-prefix}-exposure:before {
  content: "\f1fd";
}
.@{md-css-prefix}-exposure-minus-1:before {
  content: "\f1fe";
}
.@{md-css-prefix}-exposure-minus-2:before {
  content: "\f1ff";
}
.@{md-css-prefix}-exposure-zero:before {
  content: "\f200";
}
.@{md-css-prefix}-exposure-plus-1:before {
  content: "\f201";
}
.@{md-css-prefix}-exposure-plus-2:before {
  content: "\f202";
}
.@{md-css-prefix}-filter:before {
  content: "\f203";
}
.@{md-css-prefix}-filter-1:before {
  content: "\f204";
}
.@{md-css-prefix}-filter-2:before {
  content: "\f205";
}
.@{md-css-prefix}-filter-3:before {
  content: "\f206";
}
.@{md-css-prefix}-filter-4:before {
  content: "\f207";
}
.@{md-css-prefix}-filter-5:before {
  content: "\f208";
}
.@{md-css-prefix}-filter-6:before {
  content: "\f209";
}
.@{md-css-prefix}-filter-7:before {
  content: "\f20a";
}
.@{md-css-prefix}-filter-8:before {
  content: "\f20b";
}
.@{md-css-prefix}-filter-9:before {
  content: "\f20c";
}
.@{md-css-prefix}-filter-9-plus:before {
  content: "\f20d";
}
.@{md-css-prefix}-filter-b-and-w:before {
  content: "\f20e";
}
.@{md-css-prefix}-filter-center-focus:before {
  content: "\f20f";
}
.@{md-css-prefix}-filter-drama:before {
  content: "\f210";
}
.@{md-css-prefix}-filter-frames:before {
  content: "\f211";
}
.@{md-css-prefix}-filter-hdr:before {
  content: "\f212";
}
.@{md-css-prefix}-filter-none:before {
  content: "\f213";
}
.@{md-css-prefix}-filter-tilt-shift:before {
  content: "\f214";
}
.@{md-css-prefix}-filter-vintage:before {
  content: "\f215";
}
.@{md-css-prefix}-flare:before {
  content: "\f216";
}
.@{md-css-prefix}-flash-auto:before {
  content: "\f217";
}
.@{md-css-prefix}-flash-off:before {
  content: "\f218";
}
.@{md-css-prefix}-flash-on:before {
  content: "\f219";
}
.@{md-css-prefix}-flip:before {
  content: "\f21a";
}
.@{md-css-prefix}-gradient:before {
  content: "\f21b";
}
.@{md-css-prefix}-grain:before {
  content: "\f21c";
}
.@{md-css-prefix}-grid-off:before {
  content: "\f21d";
}
.@{md-css-prefix}-grid-on:before {
  content: "\f21e";
}
.@{md-css-prefix}-hdr-off:before {
  content: "\f21f";
}
.@{md-css-prefix}-hdr-on:before {
  content: "\f220";
}
.@{md-css-prefix}-hdr-strong:before {
  content: "\f221";
}
.@{md-css-prefix}-hdr-weak:before {
  content: "\f222";
}
.@{md-css-prefix}-healing:before {
  content: "\f223";
}
.@{md-css-prefix}-image:before {
  content: "\f224";
}
.@{md-css-prefix}-image-aspect-ratio:before {
  content: "\f225";
}
.@{md-css-prefix}-iso:before {
  content: "\f226";
}
.@{md-css-prefix}-landscape:before {
  content: "\f227";
}
.@{md-css-prefix}-leak-add:before {
  content: "\f228";
}
.@{md-css-prefix}-leak-remove:before {
  content: "\f229";
}
.@{md-css-prefix}-lens:before {
  content: "\f22a";
}
.@{md-css-prefix}-looks:before {
  content: "\f22b";
}
.@{md-css-prefix}-looks-1:before {
  content: "\f22c";
}
.@{md-css-prefix}-looks-2:before {
  content: "\f22d";
}
.@{md-css-prefix}-looks-3:before {
  content: "\f22e";
}
.@{md-css-prefix}-looks-4:before {
  content: "\f22f";
}
.@{md-css-prefix}-looks-5:before {
  content: "\f230";
}
.@{md-css-prefix}-looks-6:before {
  content: "\f231";
}
.@{md-css-prefix}-loupe:before {
  content: "\f232";
}
.@{md-css-prefix}-movie-creation:before {
  content: "\f233";
}
.@{md-css-prefix}-nature:before {
  content: "\f234";
}
.@{md-css-prefix}-nature-people:before {
  content: "\f235";
}
.@{md-css-prefix}-navigate-before:before {
  content: "\f236";
}
.@{md-css-prefix}-navigate-next:before {
  content: "\f237";
}
.@{md-css-prefix}-palette:before {
  content: "\f238";
}
.@{md-css-prefix}-panorama:before {
  content: "\f239";
}
.@{md-css-prefix}-panorama-fisheye:before {
  content: "\f23a";
}
.@{md-css-prefix}-panorama-horizontal:before {
  content: "\f23b";
}
.@{md-css-prefix}-panorama-vertical:before {
  content: "\f23c";
}
.@{md-css-prefix}-panorama-wide-angle:before {
  content: "\f23d";
}
.@{md-css-prefix}-photo:before {
  content: "\f23e";
}
.@{md-css-prefix}-photo-album:before {
  content: "\f23f";
}
.@{md-css-prefix}-photo-camera:before {
  content: "\f240";
}
.@{md-css-prefix}-photo-library:before {
  content: "\f241";
}
.@{md-css-prefix}-portrait:before {
  content: "\f242";
}
.@{md-css-prefix}-remove-red-eye:before {
  content: "\f243";
}
.@{md-css-prefix}-rotate-left:before {
  content: "\f244";
}
.@{md-css-prefix}-rotate-right:before {
  content: "\f245";
}
.@{md-css-prefix}-slideshow:before {
  content: "\f246";
}
.@{md-css-prefix}-straighten:before {
  content: "\f247";
}
.@{md-css-prefix}-style:before {
  content: "\f248";
}
.@{md-css-prefix}-switch-camera:before {
  content: "\f249";
}
.@{md-css-prefix}-switch-video:before {
  content: "\f24a";
}
.@{md-css-prefix}-tag-faces:before {
  content: "\f24b";
}
.@{md-css-prefix}-texture:before {
  content: "\f24c";
}
.@{md-css-prefix}-timelapse:before {
  content: "\f24d";
}
.@{md-css-prefix}-timer:before {
  content: "\f24e";
}
.@{md-css-prefix}-timer-3:before {
  content: "\f24f";
}
.@{md-css-prefix}-timer-10:before {
  content: "\f250";
}
.@{md-css-prefix}-timer-auto:before {
  content: "\f251";
}
.@{md-css-prefix}-timer-off:before {
  content: "\f252";
}
.@{md-css-prefix}-tonality:before {
  content: "\f253";
}
.@{md-css-prefix}-transform:before {
  content: "\f254";
}
.@{md-css-prefix}-tune:before {
  content: "\f255";
}
.@{md-css-prefix}-wb-auto:before {
  content: "\f256";
}
.@{md-css-prefix}-wb-cloudy:before {
  content: "\f257";
}
.@{md-css-prefix}-wb-incandescent:before {
  content: "\f258";
}
.@{md-css-prefix}-wb-irradescent:before {
  content: "\f259";
}
.@{md-css-prefix}-wb-sunny:before {
  content: "\f25a";
}