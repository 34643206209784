.panel {
    position: relative;
    border: 0;
    border-radius: @border-radius-small;
    // overflow: hidden;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);
    margin: 0 auto @grid-gutter-width / 2;
    max-width: @screen-tablet;
    label {
        margin: 0;
    }
    ul.actions {
        position: absolute;
        right: 8px;
        z-index: 2;
        top: 8px;
    }
}
.panel--full-width { max-width: 100%; }
.panel-heading {
    border-bottom: 1px solid @gray-lighter;
    font-weight: 500;
    color: @gray-dark;
    border-radius: 0;
    padding: 16px;
    h2 {
        font-size: @font-size-large;
        line-height: 1;
        margin: 0;
    }
}
.panel-body {
    padding: 16px;
    @media (min-width: @screen-sm-min) {
        padding: 24px;
    }
    p {
        &:last-child {
            margin: 0;
        }
    }
}
.panel-footer {
    border: 0;
    padding: 16px;
}
.panel-intro {
    .clearfix;
    background: @brand-primary;
    color: #fff;
    padding: @grid-gutter-width;
    position: relative;
    @media (min-width: @screen-sm-min) {
        min-height: 184px;
        padding-left: 184px;
    }
    h2 {
        font-size:  @font-size-h1;
        color: #fff;
        margin-top: 0;
        .text-center;
        @media (min-width: @screen-sm-min) {
            font-size: @grid-gutter-width;
            text-align: left;
        }
    }
    img, .icon-avatar {
        display: block;
        height: 72px;
        width: 72px;
        margin: 0 auto 12px;
        @media (min-width: @screen-sm-min) {
            height: 124px;
            width: 124px;
            position: absolute;
            left: 30px;
        }
    }
    .icon-avatar {
        line-height: 72px;
        .md {
            font-size: 36px;
        }
        @media (min-width: @screen-sm-min) {
            line-height: 124px;
            .md {
                font-size: 72px;
            }
        }
    }

    .info {
        .list-unstyled;
        margin: 0 15px 0 0;
        .pull-left;
        li {
            position: relative;
            padding-left: 24px;
            min-height: @line-height-computed;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &.ib {
                margin-right: 30px;
                display: inline-block;
            }
        }
        i {
            position: absolute;
            left: 0;
            color: rgba(255,255,255,.6);
        }
    }


    ul.actions {
        > li {
            > a {
                &:hover {
                    > i {
                        color: #fff;
                    }
                }
                > i {
                    color: @gray-light;
                }
            }
        }
        > .open  > a {
            > i {
                color: #fff;
            }
        }
    }
    +.tab-nav {
        background: @brand-primary;
        margin-top: -30px;
        a {
            color: rgba(255,255,255,.7);
            &:hover {
                color: #fff;
            }
        }
        .active {
            a {
                color: #fff;
                &:after {
                        background: @brand-accent;
                }
            }
        }
    }

}

.panel-intro a {
    color: #fff;
}
.panel-intro a:hover {
    text-decoration: underline;
}