
#sidebar {
    position: fixed;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    top: @navbar-height;
    top: 0;
    // height: calc(~'100% - 50px');
    height: 100%;
    height: 100vh;
    -webkit-transform: translate3d(-@navbar-width,0,0);
    transform: translate3d(-@navbar-width,0,0);
    z-index: 10000;
    width: @navbar-width;
    overflow-x: hidden;
    .opacity(0);
    .transition(transform .3s ease-in-out);
    font-weight: 500;
    &.toggled {
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      .opacity(1);
    }

    @media (min-width: @screen-lg) {
        -webkit-transform: translate3d(0,0,0) !important;
        transform: translate3d(0,0,0) !important;
        .opacity(1);
    }

    .sidebar-inner {
        height: 100%;
        overflow-y: auto;
    }
}

#sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    will-change: opacity;
    @media (min-width: @screen-lg) {
        display: none;
    }
}
.main-menu {
    margin: 0;
    .nav;
    a {
        color: @gray-dark;
        padding: 12px 16px 12px 56px !important;
        &:hover {
            background: @gray-lighter;
            color: @gray-darker;
        }
    }
    .active a {
        background: darken(@gray-lighter, 5%);
        color: @gray-darker;
    }
    .divider {
        border-bottom: 1px solid @gray-light;
        margin: 8px 0px;
    }
    .sub-menu {
        > a {
            &:before {
                .icon;
                content: "\f0fb";
                position: absolute;
                font-size: @font-size-h2;
                line-height: 1;
                right: 15px;
                top: 15px;
            }
        }
        &.toggled {
            >  a {
                &:before {
                    content: "\f111";
                }
            }
        }

        ul {
            .nav;
            background: @gray-lighter;
            padding: 0;
            display: none;
            a {
                color: lighten(@gray, 10%);
                &:hover {
                    color: @gray-darker;
                }
            }
        }
    }
    i {
        font-size: @font-size-h2;
        line-height: 1;
        min-width: 30px;
        position: absolute;
        left: 15px;
        top: 12px;
    }
}
.main-menu .heading {
    color: lighten(@gray, 30%);
    padding: 16px !important;
}

.profile-menu {
    height: 100px;
    background: darken(@brand-primary, 4%);

    .avatar {
        margin: 8px 16px;
        border-radius: 50%;
        position: relative;
        z-index: 50;
    }

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        z-index: 40;
        background: url(../img/profile-bg.jpg) 0 0 no-repeat;
        background-size: 100% auto;
        width: 100%;
        height: 100%;
        .opacity(.2);
    }

    > a {
        color: #fff;
        display: block;
        padding: 8px 16px;
        position: relative;
        .transition(background .3s ease-in-out);
        z-index: 50;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 36px;
        &:before {
            .icon;
            content: "\f29e";
            font-size: @font-size-h1;
            position: absolute;
            right: 8px;
            bottom: 8px;
        }
        &:hover {
           background: rgba(0,0,0,.15);
        }
    }
}