
.calendar-app {
    min-height: ((40px * 2) + (@table-cell-padding * 4)) + 2;
    display: table;
    width: 100%;
}

.calendar-wrap {
    display: table-row;
}

.calendar-sidebar, .calendar-drop {
    display: table-cell;
    vertical-align: top;
}
.calendar-sidebar {
    padding: @table-cell-padding;
    padding-bottom: 0;
    position: relative;
    width: 30%;
    .driver-info {
        overflow: hidden;
        margin-bottom: 10px;
        strong {
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
            // min-width: 80px;
        }
    }
    .avatar {
        margin: 0 10px;
    }
    .truck, .trailer {
        display: inline-block;
        margin-bottom: 10px;
        .make-md-column(6, 0);
    }
}
.calendar-sidebar, .calendar-drop {
    border-bottom: 0;
}
.calendar-drop {
    border-left: 1px solid @table-border-color;
}

.ui-sortable-placeholder {
    background: @table-bg-hover;
    box-shadow: inset 0 -1px 0 @gray-lighter;
    visibility: visible !important;
    position: relative;
    &:before {
        background: @gray-lighter;
        content: '';
        position: absolute;
        top: 10px;
        left: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
}

#toplan .agendaEvent.ui-sortable-placeholder {
    display: none;
}

.ui-sortable-helper {
    // width: 240px !important;
    .opacity(.9);
    .td {
        border: 0;
    }
    .cell-actions {
        display: none;
    }
    .tr {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
    }
}

.itemPicker .itemPicker-togle {
    color: @text-color;
}